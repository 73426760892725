
@tailwind base;
@tailwind components;
@tailwind utilities;
/* In your global CSS */

html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;

  padding: 0;
  
 
}

.rbc-calendar {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.rbc-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  font-size: 18px;
  font-weight: bold;
}

.rbc-event {
  color: #000;
  border: none;
  padding: 5px;
}

.rbc-month-view {
  border: none;
}

.rbc-header {
  background: #f1f3f5;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6;
}

.rbc-day-bg {
  background: #fff;
}
